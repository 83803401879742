const TermsAndConditionContent = ({ secondary = true }) => (
  <div className={`text-sm ${secondary ? 'bg-basic-200 text-gray-100 p-20' : ''}`}>

    <p><b>TERMS AND CONDITIONS FOR THE USE OF DEON
      TRADING SYSTEM </b></p>

    <br />

    <p><b>WARNING:</b></p>

    <p>Before you click the 'accept' button at the
      end of this document, please carefully read these terms and conditions (the
      "Terms"), which will govern the use of the Site by you and each User.
      In order to use the System via this Site or directly, each User must agree to
      be bound by the Terms, and evidence this by clicking the 'accept' button.</p>
    <br />
    <p><b>Definitions</b></p>

    <p>In these Terms, unless the context requires
      otherwise, the following words shall have the following meanings:</p>
    <br />
    <table border="1" cellSpacing="0" cellPadding="0" className='border border-gray-400'>
      <tbody className='p-4'><tr className='border-gray-400 border'>
        <td width="149" valign="top" className="p-2">
          <p><b>Content</b></p>
        </td>
        <td width="467" valign="top" className="p-2" >
          <p>means all information contained on the Site in any form including
            without limitation any and all instrument descriptions, instrument pricing,
            instrument trade activity, text, audio, video, graphics, images, photographs,
            data, databases, software and/or advertisements;</p>
        </td>
      </tr>
        <tr className='border-gray-400 border'>
          <td width="149" valign="top" className="p-2" >
            <p><b>Customer</b></p>
          </td>
          <td width="467" valign="top" className="p-2" >
            <p>means the User entity (corporation,
              partnership, joint venture or otherwise) which is entering into these Terms
              in order to use the Site;</p>
          </td>
        </tr>
        <tr className='border-gray-400 border'>
          <td width="149" valign="top" className="p-2" >
            <p><b>Custom Pages</b></p>
          </td>
          <td width="467" valign="top" className="p-2" >
            <p>means those pages of the Site which Users can amend;</p>
            <br />
          </td>
        </tr>
        <tr className='border-gray-400 border'>
          <td width="149" valign="top" className="p-2" >
            <p><b>Individual user</b></p>
          </td>
          <td width="467" valign="top" className="p-2" >
            <p>means individual persons using the Site
              including without limitation employees, partners and independent contractors
              of a customer;</p>
          </td>
        </tr>
        <tr className='border-gray-400 border'>
          <td width="149" valign="top" className="p-2" >
            <p><b>Instruments</b></p>
          </td>
          <td width="467" valign="top" className="p-2" >
            <p>means fixed income securities and derivatives thereof;</p>
          </td>
        </tr>
        <tr className='border-gray-400 border'>
          <td width="149" valign="top" className="p-2" >
            <p><b>Site</b></p>
          </td>
          <td width="467" valign="top" className="p-2" >
            <p>means this website, the Deon Trading System
              and the web interface accessed via https://sandbox.deon.sg via web browser</p>
          </td>
        </tr>
        <tr className='border-gray-400 border'>
          <td width="149" valign="top" className="p-2" >
            <p><b>Deon</b></p>
          </td>
          <td width="467" valign="top" className="p-2" >
            <p>means Deon Technologies Pte. Ltd., being the entity providing
              price information to Users via the Deon Trading System;</p>
          </td>
        </tr>
        <tr className='border-gray-400 border'>
          <td width="149" valign="top" className="p-2" >
            <p><b>Deon Trading System</b></p>
          </td>
          <td width="467" valign="top" className="p-2" >
            <p>means DEON's electronic platform, providing
              price information relating to various Instruments and details of trade
              confirmations via a Whiteboard to Users from time to time;</p>
          </td>
        </tr>
        <tr className='border-gray-400 border'>
          <td width="149" valign="top" className="p-2" >
            <p><b>View Only User</b></p>
          </td>
          <td width="467" valign="top" className="p-2" >
            <p>means a User that may only view the Content of the Site for the
              purposes of validation of Content for intended use and may not either
              directly or indirectly interact with Content nor use the Content for the
              pricing, trading or valuation of financial instruments;</p>
          </td>
        </tr>
        <tr className='border-gray-400 border'>
          <td width="149" valign="top" className="p-2" >
            <p><b>Whiteboard</b></p>
          </td>
          <td width="467" valign="top" className="p-2" >
            <p>means the application used by Users to manage
              and view Content via their User interface; and</p>
          </td>
        </tr>
        <tr className='border-gray-400 border'>
          <td width="149" valign="top" className="p-2" >
            <p><b>“You” or “User”</b></p>
          </td>
          <td width="467" valign="top" className="p-2" >
            <p>means each person or entity (including without limitation
              Individual Users and Customers) entering into these Terms in order to use the
              Site and/or System</p>
          </td>
        </tr>
      </tbody></table>
    <br />
    <br />
    <p><b>Terms</b></p>
    <br />
    <p><b>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; General</b></p>

    <br />

    <p>1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The User and Customer
      acknowledges and agrees that any and all intellectual property rights which may
      subsist in the Site and/or the Content are owned by and shall remain the
      property of DEON or its licensors. These intellectual property rights include
      without limitation copyright, trade marks, service marks, trade names, the
      underlying software, the design, graphics, layout, look, feel and structure of
      the Site, database rights, design rights, domain names, and rights to goodwill
      and/or to sue for passing off. No material from this Site or from the DEON
      Trading System may be copied, reproduced, republished, uploaded, posted,
      transmitted, or distributed in any way, without the express written permission
      of DEON. Each Individual User and each Customer warrants that the Individual
      User has the authority to enter into this agreement with DEON on behalf of the
      Individual User and the Customer.</p>

    <br />

    <p>1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; These Terms will apply to all
      future uses of this Site by Users unless specifically otherwise agreed in
      writing. DEON may, at its discretion, change, modify, add or remove these Terms
      at any time. Where DEON believes that such an action will significantly impact
      upon your use of the Site we will make reasonable endeavours to use
      proportionate and reasonable methods to update you of such a change but in any
      event please check the Site periodically for any and all changes to these
      Terms. Your continued use of the Site and/or any other DEON system following
      the posting of changes to these Terms will constitute your acceptance to those
      changes, whether or not you have actual notice of changes made.</p>

    <br />

    <p>1.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If a Customer or Individual
      User except for the View Only User fails to maintain all necessary licences and
      consents required for the use of this Site, or required to trade with DEON in
      accordance with the General Dealing Terms, then such Customer and/or Individual
      User (as applicable) shall inform DEON immediately and DEON is authorised by
      the Individual User and the Customer, respectively, to take all necessary
      action to prevent the Individual User and/or Customer from continuing to have
      access to and use of the Site. If DEON takes such steps, the Customer and the
      Individual User agrees that DEON shall not be liable for any loss or damage
      sustained as a result of such action.</p>

    <br />

    <p>1.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DEON may, without notice and
      at any time, amend or suspend the transmission of Content through this Site
      and/or suspend or terminate your access to and use of the Site.</p>

    <br />

    <p>1.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Customer and each
      Individual User irrevocably authorises DEON and/or its duly authorised agent(s)
      to carry out such investigations as DEON may reasonably require in order to
      ensure that the Individual User and the Customer are respectively complying
      with these Terms. Without limiting the generality of the foregoing, the
      Customer authorises DEON and/or its duly authorised agent(s) to have access to
      the Customer's records, books of account, and other records as reasonably
      required by DEON in order to establish whether the Customer is complying with
      these Terms. The Customer authorises DEON or its duly authorised agent(s) to
      have access to the Customer's premises for the purposes of carrying out its
      investigations.</p>

    <br />

    <p>1.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To the extent that it is
      required by the Customer and/or the Individual User, for the bona fide use of
      the DEON Trading System, DEON hereby grants to the Customer and/or the
      Individual User (as applicable) a revocable, non-exclusive, non-transferable
      licence to access and use the Site solely for the purpose of:</p>

    <br />

    <p>Viewing its
      Content (and adjusting the way in which it views Content via the Whiteboard);
      and</p>

    <p>Creating and
      amending Custom Pages, subject to and in accordance with these Terms (the
      "Licence").</p>

    <p>Submitting orders
      to DEON in accordance with the (general) dealing terms and conditions as
      detailed in 1.7 below.</p>

    <p>DEON may revoke the Licence with immediate
      effect and without penalty at any time in its discretion.</p>

    <br />

    <p>1.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any trading activity being
      orders to the order book, orders in an auction session or execution is governed
      by the General Dealing Terms and Conditions as published from time to time on:
      www.deonsg.com/regulatoryInfo/generalTermsOfBusiness.</p>

    <br />

    <p><b>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Liability</b></p>

    <br />

    <p>2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; These Terms do not exclude or
      in any way limit liability for fraud, death, or personal injury caused by
      negligence and/or any liability to the extent the same may not be excluded or
      limited as a matter of law.</p>

    <br />

    <p>2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Subject to clause 2.1 and to
      the extent permissible by law, DEON's entire liability in respect of any direct
      or indirect losses howsoever arising and whether arising out of breach of
      contract, tort (including negligence), breach of statutory duty, indemnity or
      otherwise arising out of or relating to its obligations under these Terms
      and/or to the use by any party of the Site, including without limitation any
      trade undertaken in connection with the use of this Site shall be limited to
      the total fees (if any) paid by Customer under these Terms in the twelve month
      period preceding the date of the relevant claim.</p>

    <br />

    <p>2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Subject to clause 2.1 and
      without prejudice to clause 2.2, DEON expressly excludes liability for any and
      all loss of profits, loss of business, loss of contracts, loss of revenue, loss
      of management time, loss of goodwill or reputation, loss of damage to or
      corruption of data, wasted expenses, anticipated savings, or indirect or
      consequential loss or damage of any kind, in each case howsoever arising,
      whether such loss or damage was foreseeable or in the contemplation of the
      parties to the Terms and whether arising out of breach of contract, tort
      (including negligence), breach of statutory duty, indemnity or otherwise.</p>

    <br />

    <p>2.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The terms of this clause 2 are
      in addition to, and not in derogation of, the effect of the disclaimers which
      appear on the Site from time to time.</p>

    <br />

    <p>2.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Customer accepts and
      agrees that it is in the best position to consider and evaluate any loss that
      it may suffer as a result of entering into this agreement or any trade in
      association with DEON by using the System.</p>

    <br />

    <p><b>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Indemnities</b></p>

    <br />

    <p>3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Customer shall fully
      indemnify and hold DEON harmless from and against any and all claims, demands,
      actions, liabilities, costs, expenses (including without limitation legal
      expenses), loss and damage threatened against or suffered by DEON arising out
      of or relating to:</p>

    <br />

    <p>3.1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; any infringement or alleged
      infringement of any intellectual property right existing in relation to the use
      or misuse of the Site;</p>

    <br />

    <p>3.1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; any breach of the Licence;
      and/or</p>

    <br />

    <p>3.1.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the use or misuse of the Site,
      including without limitation use or misuse of the DEON Trading System and/or
      Login Details (defined below) whether or not the Customer authorised, consented
      to, or was aware of such use, misuse or other activity.</p>

    <br />

    <p><b>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Access and Confidentiality</b></p>

    <br />

    <p>4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Each Individual User must
      enter a unique username and secure password ("Login Details") in
      order to access the DEON Trading System. Login Details are personal to each
      Individual User and the Customer and each Individual User shall ensure that no
      person other than the applicable Individual User uses the Login Details to
      access the DEON Trading System. Customers shall promptly notify DEON of any
      breach of security with regard to Login Details or any improper use of the
      same. A breach by any Customer's Individual User of any provision of these
      Terms will constitute a breach by the Customer.</p>

    <br />

    <p><b>4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DEON Confidential Information</b></p>

    <br />

    <p>4.2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All Confidential Information
      obtained from and provided by DEON and accessed from DEON shall be deemed to be
      the property of DEON and the User undertakes to maintain and procure the
      maintenance of the confidentiality of Confidential Information and procure the
      keeping of all</p>

    <br />

    <p>Confidential Information secure and
      protected against theft, damage, loss or unauthorized access, and not at any
      time to use or authorize or permit the use of, or disclose, exploit, copy,
      modify any Confidential Information, or authorize or permit any third party to
      do the same, other than for the sole purpose of the performance of its rights
      and obligations under and in accordance with these Terms.</p>

    <br />

    <p>4.2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The term “Confidential
      Information” shall be deemed to include all documents (including this
      Agreement), and other information, however transmitted from DEON to User,
      including to financial information and accounts, analyses, compilations,
      studies, reports, know-how, business plans, business methods, processes, pricing
      policies, identity of clients and prospective clients, software, user manuals,
      specifications, configurations, internal systems or controls used, trade
      secrets, dealings and any other information which is designated proprietary and
      confidential. “Confidential Information” shall also include documents prepared
      by a party that contain, reflect or are based upon, in whole or in part, any
      Confidential Information.</p>

    <br />

    <p>4.2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The following categories,
      however, shall not be considered Confidential Information for the purposes of
      this clause:</p>

    <p>(a) information
      which is or enters into the public domain through no fault or act of the
      receiving party;</p>

    <p>(b) information
      which is required to be disclosed by law, court, legal process or as requested
      by any authority or body or agency in accordance with which the Customer is
      required or accustomed to act;</p>

    <p>(c) was known to
      the User prior to DEON’s disclosure of such information to the User without any
      confidentiality obligations; or</p>

    <p>(d) became known
      to the User from a source other than DEON other than by breach of an obligation
      of confidentiality owed to DEON</p>

    <br />

    <p><b>4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; User Confidential
      Information</b></p>

    <br />

    <p>4.3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All Confidential Information
      related to User’s data shall specifically including portfolio or position
      information be deemed to be the property of User and DEON undertakes to
      maintain and procure the maintenance of the confidentiality of Confidential
      Information and procure the keeping of all Confidential Information secure and
      protected against theft, damage, loss or unauthorized access, and not at any
      time to use or authorize or permit the use of, or disclose, exploit, copy,
      modify any Confidential Information, or authorize or permit any third party to
      do the same, other than for the sole purpose of the performance of its rights and
      obligations under and in accordance with these Terms.</p>

    <br />

    <p>4.3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The term “Confidential
      Information” shall be deemed to include all documents (including this
      Agreement), and other information, however transmitted from User to DEON,
      including to financial information and accounts, analyses, compilations,
      studies, reports, know-how, business plans, business methods, processes,
      pricing policies, identity of clients and prospective clients, software, user
      manuals, specifications, configurations, internal systems or controls used,
      trade secrets, dealings and any other information which is designated
      proprietary and confidential. “Confidential Information” shall also include
      documents prepared by a party that contain, reflect or are based upon, in whole
      or in part, any Confidential Information.</p>

    <br />

    <p>4.3.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The following categories,
      however, shall not be considered Confidential Information for the purposes of
      this clause:</p>

    <br />

    <p>(a) information
      which is or enters into the public domain through no fault or act of the
      receiving party;</p>

    <p>(b) information
      which is required to be disclosed by law, court, legal process or as requested
      by any authority or body or agency in accordance with which the Customer is
      required or accustomed to act;</p>

    <p>(c) was known to
      DEON prior to the User’s disclosure of such information to DEON without any
      confidentiality obligations; or</p>

    <p>(d) became known
      to DEON from a source other than the User other than by breach of an obligation
      of confidentiality owed to the User</p>

    <br />

    <p><b>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Editing the Site</b></p>

    <br />

    <p>5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DEON may, without notice,
      refuse to post or transmit or may remove, edit, delete, amend or alter in any
      way any part or whole of the Site at its sole discretion.</p>

    <br />

    <p><b>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Exclusion of Warranties</b></p>

    <br />

    <p>6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Although DEON attempts to
      ensure that the Content is accurate, this Site is provided "as is"
      and on an "as available" basis and may not be accurate or up to date.
      The Content may or may not have been prepared by DEON but is made available
      without responsibility on the part of DEON.DEON does not guarantee the accuracy,
      timeliness, completeness, performance or fitness for a particular purpose of
      the Content contained on, distributed through, streamed, linked, downloaded or
      accessed from the Site. Subject to clause 2.1, no responsibility is accepted by
      or on behalf of DEON for any errors, omissions, or inaccuracies in the Content.</p>

    <br />

    <p>6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DEON does not represent or
      warrant the quality, availability, right to use, ownership or reliability of
      any of the Content which a User of the Site may see or use. Any dealings with
      any other site operators shall be on the terms (if any) of that third party
      operator.</p>

    <br />

    <p>6.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; No action should be taken or
      omitted to be taken on the basis of the Content. Any reliance you place upon
      any of the Content in the Site will be at the sole risk of the Individual User and/or
      Customer. DEON is not selling or offering for sale any products or services
      outlined or described on the Site nor is it acting as agent of any person
      selling or offering for sale any products or services described in the Content.</p>

    <br />

    <p>6.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Except as explicitly stated to
      the contrary in these Terms, the User hereby acknowledges and agrees that DEON
      disclaims to the extent permissible by applicable law any and all warranties,
      conditions, representations and terms, whether express or implied by statute,
      common law or otherwise, with respect to the Site including without limitation
      warranties of satisfactory quality, merchantability and fitness for a
      particular purpose, non-infringement, compatibility, security, accuracy and/or
      any similar or equivalent warranty.</p>

    <br />

    <p><b>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Restrictions on use of the
      Site and of the DEON Trading System</b></p>

    <br />

    <p><b>7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Each Individual User and the
      Customer shall:</b></p>

    <br />

    <p>7.1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; abide by all applicable laws,
      regulations and codes of conduct and be solely responsible for all things
      arising from your use of this Site and/or your dealings with DEON;</p>

    <br />

    <p>7.1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not infringe any third party
      rights, including without limitation the intellectual property rights of
      others. They shall not use any trade marks or other intellectual property
      rights of DEON, or in any way represent a connection with DEON or link to this
      Site or the DEON Trading System in any manner whatsoever without express prior
      written consent;</p>

    <br />

    <p>7.1.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not damage, interfere with or
      disrupt this Site or do anything that may interrupt or impair its functionality
      or cause disruption to the general business of DEON or its associated
      companies;</p>

    <br />

    <p>7.1.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not obtain or attempt to obtain
      unauthorised access, through whatever means, to the Site or any information or
      services provided in connection with the Site, or to computer systems operated
      by DEON;</p>

    <br />

    <p>7.1.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not publish, or otherwise
      transmit any defamatory, offensive, obscene, or unlawful material through or in
      connection with this Site;</p>

    <br />

    <p>7.1.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not post to or use the Site to
      distribute or send any illegal material of any kind, including, but not limited
      to, illegal material in the form of text, graphics, video, programs or audio
      files;</p>

    <br />

    <p>7.1.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not threaten, harass, stalk,
      abuse, disrupt or otherwise violate the rights of others, including rights of
      publicity and privacy through or in connection with the Site;</p>

    <br />

    <p>7.1.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not misuse the Site by
      introducing viruses, Trojans, worms, logic bombs or other material which is
      malicious or technologically harmful;</p>

    <br />

    <p>7.1.9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not use this Site for
      distributing, disseminating or posting any chain letters, junk mail, 'spamming'
      material or any other form of bulk communication; and</p>

    <br />

    <p>7.1.10&nbsp;&nbsp;&nbsp; not assign these Terms without
      prior written notice to DEON.</p>

    <br />

    <p><b>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Links</b></p>

    <br />

    <p>8.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; From time to time DEON may
      make available links from the Site to other sites. These will not necessarily
      be in any way approved, checked, edited, vetted or endorsed by DEON. DEON shall
      not be responsible or liable in any way for the content, advertising or
      products available from such sites, the quality, functionality, suitability or
      legality of such sites or for any dealings, or the consequences of such
      dealings, with other site operators. Any dealings with other operators shall be
      on the terms and conditions (if any) of those operators. The Customer waives
      any claim it may have against DEON with respect to such sites. The Customer
      agrees to indemnify and hold harmless DEON from and against any and all claims,
      actions, liabilities, losses, damages, costs, and expenses (including without
      limitation legal expenses) suffered or incurred by DEON in relation to such
      dealings. Any arrangements made with any other party and the Customer are the
      sole responsibility of the Customer.</p>

    <br />

    <p>8.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Other parties may not link or
      deep link to this Site without the express consent of DEON. However, if that
      does occur, DEON is not liable for any dealings with these unauthorised sites
      or any content which may be displayed or linked to by these third parties.</p>

    <br />

    <p><b>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Miscellaneous Provisions</b></p>

    <br />

    <p>9.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DEON may assign its rights and
      obligations under these Terms and upon any such assignment DEON will be
      relieved of any further obligation to the Customer hereunder.</p>

    <br />

    <p>9.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Customer may assign its
      rights and obligations under these Terms to any of its affiliates, subject to
      providing a written notice to DEON 5 business days before the date of such
      assignment.</p>

    <br />

    <p>9.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any failure to exercise any
      particular right or provision of these Terms shall not constitute a waiver of
      such right or provision unless acknowledged and agreed to by DEON to you in
      writing.</p>

    <br />

    <p>9.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DEON reserves the right to
      vary these Terms from time to time.</p>

    <br />

    <p>9.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; No representation or warranty
      has been made by or on behalf of DEON which induced or intended to induce the
      Customer to enter into this agreement. These Terms constitute the entire
      agreement between the parties.</p>

    <br />

    <p>9.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DEON shall not be liable for
      any delay or failure to fulfil any obligation under these Terms to the extent
      such delay or failure was due to an event beyond DEON's reasonable control.</p>

    <br />

    <p><b>10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Governing Law and Jurisdiction</b></p>

    <br />

    <p>10.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; These Terms and any dispute
      arising out of or in connection with them or the service provided by DEON
      hereunder are governed exclusively by and construed in accordance with the
      Singapore law and the parties hereby submit to the jurisdiction of the Singapore
      courts.</p>

    <br />

    <p>10.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If any provision of these Terms
      is held by a court of competent jurisdiction to be invalid or unenforceable in
      whole or in part then insofar as such provision is severable from the remaining
      provisions, it shall be deemed omitted from the Terms and shall in no way
      affect the legality, validity or enforceability of the remaining provisions
      (and, if applicable, the remainder of the provision in question).</p>

  </div>
);

export default TermsAndConditionContent;
